import {ModalBase} from "../modal_base";
import {AjaxSync} from "../../shared/ajax_utilities";
import {Toast} from "../../shared/toast";

export class AssignSimVehModal extends ModalBase {
    constructor() {
        super('assign_sim_veh_modal');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.iccid').text($modalTriggerBtn.data('iccid'));
        const opr = $modalTriggerBtn.data('opr');
        if(!opr || opr.length === 0) {
            console.warn('No operator id available to assign vehicle to sim.');
            return false;
        }
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            AjaxSync({
                url: `${app.CACHE.URL_AJAX}sim_management`,
                method: 'POST',
                data: {
                    action: 'assign_veh',
                    iccid: $modalTriggerBtn.data('iccid'),
                    vehicle_id: this.MODAL_CONTAINER_EL.find('#vehicle_id').val(),
                    operator_id: $modalTriggerBtn.data('opr'),
                }
            }, {
                done: (res) => {
                    if(res.status !== 'success') {
                        Toast.error(`Unable to assign vehicle for sim.`);
                        return;
                    }

                    this.hide();
                    window.location.reload();
                }
            });
        });
    }
}